import React from 'react';

import Question from './Question/Question';
import CTAContainer from '../../../../components/UI/CTAContainer/CTAContainer';
import PrimaryCTA from '../../../../components/UI/Buttons/PrimaryCTA/PrimaryCTA';

import styles from './Why.module.css';

const Why = props => {

	let questions = props.questions.map(question=><Question question={question} key={question.icon} />)

	return (
		<section className={styles.Why}>
			<div className='container'>
				<div className='row'>
					<div className='col-sm-9'>
						<p className={styles.Question}>Do you need a prototype?</p>
						<h1 className={styles.Header}>Ask yourself these 3 questions</h1>
						<div className='my-4'>{questions}</div>
						<CTAContainer>
							<h2 className={`pe-md-3 ${styles.CTATeaser}`}>
								If you didn’t answer “yes” to all 3 questions, <br className='d-none d-xl-inline-block'/> you need a prototype!
							</h2>
							<PrimaryCTA clickHandler={()=>props.showModal(true, 'why')}>Get started today</PrimaryCTA>
						</CTAContainer>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Why;