import React from 'react';
import Modal from '../../../../components/UI/Modal/Modal';
import Plan from '../../../../components/UI/Plan/Plan';
import Tabs from '../../../../components/UI/Tabs/Tabs';

const PlansModal = props => {

	const delivery = props.tabs[0].active ? '7 day' : '3 day';

	const plans = props.plans.map(plan => {
		return (
			<Plan 
				plan={plan} 
				delivery={delivery} 
				key={plan.title}
				location='plans modal'
			/>
			)
		})

	return(
		<Modal showModal={props.modalShown} handleClose={props.handleClose}>
			<div className='row'>
				<div className='col text-center'>
					<h2 className='display-4 mb-1'>Choose a plan</h2>
					<p className='lead m-0'>No payment required to get the process started</p>  
					<Tabs tabs={props.tabs}/>
					<div className='d-md-flex justify-content-center mb-3'>
						{plans}
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default PlansModal;

