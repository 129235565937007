import React from 'react';

import BootstrapModal from 'react-bootstrap/Modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import styles from './Modal.module.css';

const Modal = props => {
	return (
		<BootstrapModal 
			show={props.showModal} 
			onHide={()=>props.handleClose(false)}
			size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
		>
			<BootstrapModal.Body>
				<div className={styles.Close} onClick={()=>props.handleClose(false)}>
					<CloseOutlinedIcon sx={{fontSize: 25}}/>
				</div>
				<div className='pt-2'>{props.children}</div>
			</BootstrapModal.Body>
		</BootstrapModal>
	)
}

export default Modal;