import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';

import { Widget } from '@typeform/embed-react';
import FormLayout from '../../components/Layouts/FormLayout/FormLayout';

import styles from './Order.module.css';

import {gtagFireEvent, gtagCategoryNames, gtagEventNames} from '../../shared/gtagIntegration';

const Order = props => {

	const location = useLocation();

	const query = new URLSearchParams(location.search);
	const type = query.get('type');
	const delivery = query.get('delivery');

	const contactClicked = () => {
		gtagFireEvent(
			gtagEventNames.CLICKED_CONTACT, 
			gtagCategoryNames.MARKETING, 
			'pricing', 
			null, 
			null
		)
	}

	return (
		<FormLayout>
			<Widget 
				id='c3Miu7wd' 
				style={{ width:'100%', height:'550px' }} 
				inlineOnMobile={true} 
				hidden={{
					type: type,
					delivery: delivery,
					source: props.source,
					medium: props.medium,
					campaign: props.campaign,
					content: props.content,
					term: props.term
				}}
			/>
			<p>Do you need help filling out the form? <Link to='/contact' className={styles.Contact} onClick={()=>contactClicked()}>Send us a short message,</Link> and we'll contact you to help.</p>
		</FormLayout>
	)
}

const mapStateToProps = state => {
	return {
		source: state.utmParamsReducer.source,
		medium: state.utmParamsReducer.medium,
		campaign: state.utmParamsReducer.campaign,
		content: state.utmParamsReducer.content,
		term: state.utmParamsReducer.term
	}
}

export default connect(mapStateToProps)(Order);

