import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './ScrollCTA.module.css';

const ScrollCTA = props => {
	return(
		<button className={`btn btn-link ${styles.ScrollCTA}`} onClick={props.clickHandler}>
			<ExpandMoreIcon />
			{props.children}
		</button>
	)
}

export default ScrollCTA;