import React, {Fragment} from 'react';
import { Link } from "react-router-dom";

import styles from './PrimaryCTA.module.css';

const PrimaryCTA = props => {

	let cta = (
		<button 
			className={`btn btn-lg btn-primary ${styles.PrimaryCTA}`}
			onClick={props.clickHandler}
		>
			{props.children}
		</button>
	)
	if (props.link) {
		cta = (
			<Link 
				className={`btn btn-lg btn-primary ${styles.PrimaryCTA}`}
				to={props.link}
				onClick={props.clickHandler}
			>
				{props.children}
			</Link>
		)
	}

	return (<Fragment>{cta}</Fragment>)
}

export default PrimaryCTA