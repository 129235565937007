import React from 'react';
import LogoWhite from '../../../assets/images/logo/logo.png';
import LogoBlack from '../../../assets/images/logo/logoBlack.png';
import {Link} from 'react-router-dom';
import styles from './Logo.module.css';


const Logo = props => {
	let LogoSizeStyle = styles.DefaultSize
	if (props.size === 'small') LogoSizeStyle = styles.Small;

	let Logo = <img className={LogoSizeStyle} src={LogoWhite} alt='PrototypeForMe Logo' />
	if (props.black) Logo = <img className={LogoSizeStyle} src={LogoBlack} alt='PrototypeForMe Logo' />

	return (
		<Link to='/' className={`navbar-brand ${styles.Logo}`}>
			{Logo}
		</Link>
	)
}

export default Logo;