import React, {Component, Fragment} from 'react';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import FullStory from 'react-fullstory'

import Home from '../Marketing/Home/Home';
import Contact from '../Contact/Contact';
import Order from '../Order/Order';
import Terms from '../Terms/Terms';
import Privacy from '../Privacy/Privacy';
import InvestorFeedback from '../InvestorFeedback/InvestorFeedback';

const ORG_ID = '17EMJ1';

class App extends Component {

	render(){

		const routes = (
			<Routes>
				<Route path='/' exact element={<Home />} />
				<Route path='/contact' exact element={<Contact />} />
				<Route path='/order' exact element={<Order />} />
				<Route path='/investor-feedback' exact element={<InvestorFeedback />} />
				<Route path='/terms' exact element={<Terms />} />
				<Route path='/privacy' exact element={<Privacy />} />
				<Route path='*' element={<Navigate replace to="/" />} />
			</Routes>
		)

		return (
			<Fragment>
				<FullStory 
					org={ORG_ID}
					namespace={'FS'}
					host={'fullstory.com'}
					debug={false}
				/>
				<BrowserRouter>
		      {routes}
		    </BrowserRouter>
	    </Fragment>
		)
	} 
}

export default App;