import React from 'react';

import Step from './Step/Step';
import ValueProp from './ValueProp/ValueProp';
import CTAContainer from '../../../../components/UI/CTAContainer/CTAContainer';
import PrimaryCTA from '../../../../components/UI/Buttons/PrimaryCTA/PrimaryCTA';

import styles from './How.module.css';

const How = props => {

	const steps = props.steps.map(step=><Step step={step} key={step.number}/>)
	const valueProps = props.valueProps.map(valueProp=><ValueProp valueProp={valueProp} key={valueProp.icon}/>)

	return (
		<section className={styles.How}>
			<div className='container'>
				<div className='row'>
					<div className='col col-xl-11'>
						<p className={styles.Question}>Wondering how it works?</p>
						<h1 className={styles.Header}>Getting your prototype is as easy as 1, 2, 3</h1>
						<div className='d-md-flex py-4 mb-2'>{steps}</div>
						<div className='mb-5'>{valueProps}</div>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-9'> 
						<CTAContainer>
							<h2 className={`pe-md-3 ${styles.CTATeaser}`}>
								Interested? Get started today!
							</h2>
							<PrimaryCTA clickHandler={()=>props.showModal(true, 'how')}>Tell us about your idea</PrimaryCTA>
						</CTAContainer>
					</div>
				</div>
			</div>
		</section>
	)
}

export default How;