import React from 'react';
import {useNavigate } from "react-router-dom";

import Logo from '../../UI/Logo/Logo';

import styles from './FormLayout.module.css';

const FormLayout = props => {

	const navigate = useNavigate();

	return (
		<section className={styles.FormLayout}>
			<div className='container'>
				<div className='row'>
					<div className='col text-center py-3 d-flex justify-content-between'>
						<Logo black size={'small'}/>
						<button className={`btn btn-link ${styles.BackButton}`} onClick={()=>navigate(-1)} >
							Back
						</button>
					</div>
				</div>
				<div className='row'>
					<div className='col'>
						{props.children}
					</div>
				</div>
			</div> 
		</section>
	)
}

export default FormLayout;




