import React from 'react'

import styles from './Step.module.css';

const Step = props => {
	return (
		<div className={styles.Step}>
			<div className={styles.Number}>{props.step.number}</div>
			<h4>{props.step.title}</h4>
			<p className='mb-1'>{props.step.description}</p>
		</div>
	)
}

export default Step