import React from 'react';
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import styles from './IntroPricing.module.css'

const IntroPricing = props => {
	return (
		<div className={styles.IntroPricing}>
			<RecommendOutlinedIcon sx={{fontSize:55, color: '#084298'}} />
			<p className={styles.Message}>
				We just launched PrototypeForMe. Get your prototype for a short-time only at introductory pricing!
			</p>
		</div>
	)
}

export default IntroPricing;