import React from 'react';

import Tab from './Tab/Tab';

import styles from './Tabs.module.css';

const Tabs = props => {

	const tabs = props.tabs.map(tab=><Tab tab={tab} key={tab.name}/>);

	return (
		<div className={styles.Tabs}>
			{tabs}
		</div>
	)
} 

export default Tabs;