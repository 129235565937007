import * as actionTypes from '../actions/actionTypes';

const initialState = {
	source: null,
	medium: null,
	campaign: null,
	content: null,
	term: null
}

const utmParamsReducer = (state=initialState, action) => {
	let updatedState = {...state};

	switch (action.type){
		case (actionTypes.SET_UTM_PARAMS):
			updatedState = {...updatedState, ...action.utmParams}
			break;
		default: break;
	}

	return updatedState;
}

export default utmParamsReducer;