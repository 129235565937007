import React from 'react';

import ReactTypingEffect from 'react-typing-effect';
import ScrollIntoView from 'react-scroll-into-view'

import MarketingNav from '../../../../components/Navbars/MarketingNav/MarketingNav';
import PrimaryCTA from '../../../../components/UI/Buttons/PrimaryCTA/PrimaryCTA';
import ScrollCTA from '../../../../components/UI/Buttons/ScrollCTA/ScrollCTA';

import styles from './Hero.module.css';

const Hero = props => {

	return (
		<section className={styles.Hero}>
			<MarketingNav />
			<div className='container pt-5'>
				<div className='row pt-4'>
					<div className='col-sm-9'>
						<p className={styles.Question}>Working on a startup idea?</p>
						<h1 className={styles.Header}>
							Get a sleek prototype in 3 days<span className={styles.RotatingText}>,<br /> 
							<ReactTypingEffect 
								text = {["get feedback from users", "recruit a technical cofounder", "raise money from investors"]} 
								typingDelay = {1000}
								speed = {150}
								eraseSpeed={150}
								eraseDelay={2000}
							/></span>
						</h1>
						<div className={styles.PrimaryCTAWrapper}>
							<PrimaryCTA clickHandler={()=>props.showModal(true, 'hero')}>Get started today </PrimaryCTA>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.BottomCTAWrapper}>
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<ScrollIntoView selector="#why">
								<ScrollCTA clickHandler={props.scrollClickHandler}>Why you need a prototype?</ScrollCTA>
							</ScrollIntoView>
						</div>
					</div>
				</div>
			</div>		
		</section>
	)
}

export default Hero;