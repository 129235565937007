import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Logo from '../../UI/Logo/Logo'; 

import styles from './MarketingNav.module.css';

import {gtagFireEvent, gtagCategoryNames, gtagEventNames} from '../../../shared/gtagIntegration';

const MarketingNav = props => {

	const navLinkClicked = link => {
		gtagFireEvent(
			gtagEventNames.CLICKED_NAVBAR_ITEM, 
			gtagCategoryNames.MARKETING, 
			link, 
			null, 
			null
		)
	}

	const contactClicked = () => {
		gtagFireEvent(
			gtagEventNames.CLICKED_CONTACT, 
			gtagCategoryNames.MARKETING, 
			'navbar', 
			null, 
			null
		)
	}

	return (
		<Navbar expand='md' variant='dark'>
			<div className='container'>
				<Logo />
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
    		<Navbar.Collapse id="basic-navbar-nav">
    			<Nav className='ms-auto'>
    				<Nav.Link href='#why' className='me-md-3' onClick={()=>navLinkClicked('why')}>Why</Nav.Link>
    				<Nav.Link href='#how' className='me-md-3' onClick={()=>navLinkClicked('how')}>How</Nav.Link>
    				<Nav.Link href='#pricing' className='me-md-3' onClick={()=>navLinkClicked('pricing')}>Pricing</Nav.Link>
    				<Nav.Link 
    					className={`btn btn-outline-light ${styles.CTA}`} 
    					href='/contact'
    					onClick={()=>contactClicked()}
    				>
    					Contact us
    				</Nav.Link>
    			</Nav>
    		</Navbar.Collapse>
			</div>
		</Navbar>
	)
}

export default MarketingNav;