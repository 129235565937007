import React from 'react';
import styles from './Tab.module.css';

const Tab = props => {
	const activeStyle = props.tab.active ? styles.Active : null;

	return (
		<div 
			className={`${styles.Tab} ${activeStyle}`}
			onClick={props.tab.clickHandler}
		>
			{props.tab.name}
		</div>
	)
}

export default Tab;

