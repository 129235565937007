import React from 'react';

import HowToRegOutlined from '@mui/icons-material/HowToRegOutlined';
import AppShortcutOutlined from '@mui/icons-material/AppShortcutOutlined';
import MonetizationOnOutlined from '@mui/icons-material/MonetizationOnOutlined';

import styles from './Question.module.css';

const Question = props => {

	const iconSize = 55;
	
	let Icon = <HowToRegOutlined sx= {{fontSize: iconSize}}/>
	if (props.question.icon === 'cofounder') Icon = <AppShortcutOutlined sx={{ fontSize: iconSize }}/> 
	if (props.question.icon === 'fundraise') Icon = <MonetizationOnOutlined sx={{ fontSize: iconSize }}/>

	return (
		<div className='mb-5'>
			<div className='d-flex'>
				<div className={styles.Icon}>{Icon}</div>
				<div>
					<h1 className={styles.Title}>{props.question.title}</h1>
					<p className={styles.Answer}>{props.question.answer}</p>
				</div>
			</div>
		</div>
	)
}

export default Question;
