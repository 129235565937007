import React from 'react';
import {Link} from 'react-router-dom';

import Logo from '../../UI/Logo/Logo';

import styles from './Footer.module.css';

const Footer = props => {

	const year = new Date().getFullYear();

	return (
		<nav className='navbar, navbar-dark, bg-dark py-2'>
			<div className='container d-flex justify-content-between align-items-center'>
				<div className='d-sm-flex align-items-center'>
					<Logo size='small'/>
					<div>
						<Link to='/terms' className={styles.Link}>Terms</Link>
						<Link to='/privacy' className={styles.Link}>Privacy policy</Link>
					</div>
				</div>
				<p className='lead m-0 pt-1 text-white'>&copy; <span>{year}</span></p>
			</div>
		</nav>
	)
}

export default Footer;

