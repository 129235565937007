import React from 'react';
import {Link} from 'react-router-dom';

import IntroPricing from './IntroPricing/IntroPricing';
import Plan from '../../../../components/UI/Plan/Plan';
import Tabs from '../../../../components/UI/Tabs/Tabs';

import styles from './Pricing.module.css';

import {gtagFireEvent, gtagCategoryNames, gtagEventNames} from '../../../../shared/gtagIntegration';

const Pricing = props => {

	const delivery = props.tabs[0].active ? '7 day' : '3 day';
	const plans = props.plans.map(plan => {
		return (
			<Plan 
				plan={plan} 
				delivery={delivery} 
				key={plan.title}
				location= 'pricing'
			/>
			)
		})
	const contactClicked = () => {
		gtagFireEvent(
			gtagEventNames.CLICKED_CONTACT, 
			gtagCategoryNames.MARKETING, 
			'pricing', 
			null, 
			null
		)
	}

	return (
		<section className={styles.Pricing}>
			<div className='container'>
				<div className='row'>
					<div className='col col-xl-12'>
						<p className={styles.Question}>How much does it cost?</p>
						<h1 className={styles.Header}>Get your prototype without breaking the bank</h1>
						<IntroPricing />
					</div>
				</div>
				<div className='row text-center'>
					<div className='col-12'>
						<Tabs tabs={props.tabs}/>
						<div className='d-md-flex justify-content-center mb-3'>
							{plans}
						</div>
						<p>Any questions? <Link to='/contact' className={styles.Contact} onClick={()=>contactClicked()} >Contact us</Link></p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Pricing;