import React from 'react';

import PrimaryCTA from '../Buttons/PrimaryCTA/PrimaryCTA';

import styles from './Plan.module.css';

import {gtagFireEvent, gtagCategoryNames, gtagEventNames} from '../../../shared/gtagIntegration';

const Plan = props => {
	
	let discountedPrice = props.plan.discounted7DayPrice;
	if (props.delivery === '3 day') discountedPrice = props.plan.discounted3DayPrice;

	let originalPrice = props.plan.original7DayPrice;
	if (props.delivery === '3 day') originalPrice = props.plan.original3dayPrice;

	const featureList = props.plan.featureList.map(feature=><li className={styles.Feature} key={feature}>{feature}</li>)

	const type = props.plan.title === 'Figma prototype' ? 'figma' : 'code';
	const delivery = props.delivery === '3 day' ? '3day' : '7day';
	const linkTo = `/order?type=${type}&delivery=${delivery}` 

	const ctaClickHandler = (location, plan, delivery) => {
		gtagFireEvent(
			gtagEventNames.SELECTED_PLAN, 
			gtagCategoryNames.MARKETING, 
			location, 
			null, 
			{plan: plan, delivery: delivery}
		)
	}


	return (
		<div className={styles.Plan}>
			<div className='flex-grow-2'>
				<h2 className={styles.Title}>{props.plan.title}</h2>
				<p className={styles.Description}>{props.plan.description}</p>
			</div>
			<div className={styles.Prices}>
				<h2 className={styles.ActivePrice}>{discountedPrice}</h2>
				<h4 className={styles.InactivePrice}>{originalPrice}</h4>
			</div>
			<div className='d-flex flex-grow-1 align-items-center'>
				<ul className={styles.FeatureList}>
					{featureList}
				</ul>
			</div>
			<PrimaryCTA link={linkTo} clickHandler={()=>ctaClickHandler(props.location, type, delivery)} >
				Get started today
			</PrimaryCTA>
			<p className={styles.NoCard}>No credit card needed</p>
		</div>
	)
}

export default Plan;