import React from 'react';
import {connect} from 'react-redux';

import { Widget } from '@typeform/embed-react'
import FormLayout from '../../components/Layouts/FormLayout/FormLayout';

const Contact = props => {

	return (
		<FormLayout>
			<Widget 
				id='EA82Meuo' 
				style={{ width:'100%', height:'600px' }} 
				inlineOnMobile={true}
				hidden={{
					source: props.source,
					medium: props.medium,
					campaign: props.campaign,
					content: props.content,
					term: props.term
				}} 
			/>
		</FormLayout>
	)
}

const mapStateToProps = state => {
	return {
		source: state.utmParamsReducer.source,
		medium: state.utmParamsReducer.medium,
		campaign: state.utmParamsReducer.campaign,
		content: state.utmParamsReducer.content,
		term: state.utmParamsReducer.term
	}
}

export default connect(mapStateToProps)(Contact);



