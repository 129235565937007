// Tracking code
export const GA_TRACKING_ID = 'G-WNFP3Q80DN';

// Functions
export const gtagUpdateConfig = config => {
	if (process.env.NODE_ENV === 'production'){ 
		window.gtag('config', GA_TRACKING_ID, {...config});
	}
}

export const gtagFireEvent = (action, category, label, value, customValues) => {
	if (process.env.NODE_ENV === 'production'){ 
	  window.gtag('event', action, {
	    event_category: category,
	    event_label: label,
	    value: value, 
	    ...customValues
	  });
	}
}

// Category names
export const gtagCategoryNames = {
	MARKETING: 'marketing'
}

// Event names
export const gtagEventNames = {
	CLICKED_PRIMARY_MARKETING_CTA: 'clicked_primary_marketing_cta',
	CLICKED_SECONDARY_MARKETING_CTA: 'clicked_secondary_marketing_cta',
	CLICKED_NAVBAR_ITEM: 'clicked_navbar_item',
	CLICKED_CONTACT: 'clicked_contact',
	SELECTED_DELIVERY_OPTION: 'selected_delivery_option',
	SELECTED_PLAN: 'selected_plan'
}