import React from 'react';

import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';

const ValueProp = props => {

	const iconSize = 55;
	let icon = <DevicesOutlinedIcon sx={{fontSize: iconSize}} />
	if (props.valueProp.icon === 'mediums') icon = <DesignServicesOutlinedIcon sx={{fontSize: iconSize}} /> 
	if (props.valueProp.icon === 'speed') icon = <SpeedOutlinedIcon sx={{fontSize: iconSize}} /> 

	return (
		<div className='d-flex align-items-center mb-4'>
			<div className='me-2'>{icon}</div>
			<h4 className='mb-0'>{props.valueProp.copy}</h4> 
		</div>
	)
}

export default ValueProp;