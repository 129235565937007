import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import Hero from './Hero/Hero';
import Why from './Why/Why';
import How from './How/How';
import Pricing from './Pricing/Pricing';
import PlansModal from './PlansModal/PlansModal';
import Footer from '../../../components/Navbars/Footer/Footer';

import {setUtmParams} from '../../../store/actions/utmParamsActions';

import {gtagFireEvent, gtagCategoryNames, gtagEventNames} from '../../../shared/gtagIntegration';

class Home extends Component {

	state = {
		questions: [
			{
				icon: 'userTest',
				title: 'Will my idea resonate with users?',
				answer: 'Are you sure it will? Abstract conversations about your idea can only go so far. A prototype can bring your idea to life for users. The feedback you get will help you better evaluate your idea and iron out the kinks in it.' 
			},
			{
				icon: 'cofounder',
				title: 'Can I recruit a technical cofounder?',
				answer: 'Assuming you are not technical yourself, the best thing to do is to recruit a technical cofounder. A prototype is a great recruiting tool. Not only does it help communicate your idea, but it also communicates your level of commitment and resourcefullness.' 
			},
			{
				icon: 'fundraise',
				title: 'Can I raise money for my idea?',
				answer: 'Whether you are raising a small amount from family and friends or a seed round from angel investors, a prototype helps illustrate the potential of your business to investors.' 
			}
		],
		howSteps: [
			{
				number: 1,
				title: 'Describe your idea',
				description: 'It is as easy as filling out a form based on lean startup thinking'
			},
			{
				number: 2,
				title: 'Get Silicon Valley magic',
				description: 'Our Silicon Valley product experts design your main screens'
			},
			{
				number: 3,
				title: 'Get prototyping excellence',
				description: 'Our global team turns those screens into a sleek prototype'
			}
		],
		howValueProps: [
			{
				icon: 'devices',
				copy: 'Get a desktop or mobile prototype'
			},
			{
				icon: 'mediums',
				copy: 'Get a prototype in Figma or in code'
			},
			{
				icon: 'speed',
				copy: 'Get a prototype in 7-days or rush order it in 3-days'
			}
		],
		pricingTabs: [
			{
				name: '7 day delivery',
				active: true,
				clickHandler: ()=>this.activateTab(0)
			},
			{
				name: '3 day delivery',
				active: false,
				clickHandler: ()=>this.activateTab(1)
			},
		],
		pricingPlans: [
			{
				title: 'Figma prototype',
				description: 'As close to the real thing as possible',
				discounted7DayPrice: '$2,000',
				original7DayPrice: '$2,500',
				discounted3DayPrice: '$2,750',
				original3dayPrice: '$3,250',
				featureList: [
					'Put together using the design software Figma',
					'Clickable and interactive',
					'Mobile or desktop',
					'Easier for a non-technical person to modify themselves'
				]
			},
			{
				title: 'Code prototype',
				description: 'Indistinguishable from the real thing',
				discounted7DayPrice: '$3,000',
				original7DayPrice: '$3,750',
				discounted3DayPrice: '$3,750',
				original3dayPrice: '$4,500',
				featureList: [
					'Put together using the React framework',
					'Clickable and interactive',
					'Mobile or desktop',
					'Difficult for a non-technical person to modify themselves'
				]
			}
		],
		showPlansModal: false,
	}


	componentDidMount(){
		const query = new URLSearchParams(window.location.search);
		const params = {
			source : query.get('utm_source'),
			medium : query.get('utm_medium'),
			campaign : query.get('utm_campaign'),
			content : query.get('utm_content'),
			term : query.get('utm_term')
		}
		this.props.setUtmParams(params);
	}

	activateTab = index => {
		let tabs = [...this.state.pricingTabs];
		tabs.map(tab=> tab.active = false)
		tabs[index].active = true;
		this.setState({pricingTabs: tabs});
		gtagFireEvent(
			gtagEventNames.SELECTED_DELIVERY_OPTION, 
			gtagCategoryNames.MARKETING, 
			null, 
			null, 
			null
		)
	}

	showHideModal = (boolean, location) => {
		this.setState({showPlansModal: boolean});
		if (!boolean) this.activateTab(0);
		if (boolean){
			gtagFireEvent(
				gtagEventNames.CLICKED_PRIMARY_MARKETING_CTA, 
				gtagCategoryNames.MARKETING, 
				location, 
				null, 
				null
			)
		}
	}

	scrollClickHandler = () => {
		gtagFireEvent(
			gtagEventNames.CLICKED_SECONDARY_MARKETING_CTA, 
			gtagCategoryNames.MARKETING, 
			null, 
			null, 
			null
		)
	}

	render(){
		return (
			<Fragment>
				<div id='hero'>
					<Hero showModal={this.showHideModal} scrollClickHandler={this.scrollClickHandler}/>
				</div>
				<div id='why'>
					<Why questions={this.state.questions} showModal={this.showHideModal}/>
				</div>
				<div id='how'>
					<How 
						steps={this.state.howSteps} 
						valueProps={this.state.howValueProps}
						showModal={this.showHideModal}
					/>
				</div>
				<div id='pricing'>
					<Pricing 
						tabs= {this.state.pricingTabs} 
						plans = {this.state.pricingPlans}
					/>
				</div>
				<Footer />
				<PlansModal 
					modalShown={this.state.showPlansModal} 
					handleClose={this.showHideModal}
					tabs= {this.state.pricingTabs} 
					plans = {this.state.pricingPlans}
				/>
			</Fragment>
		)
	} 
}

const mapStateToProps = state => {
	return {
		source: state.utmParamsReducer.source,
		medium: state.utmParamsReducer.medium,
		campaign: state.utmParamsReducer.campaign,
		content: state.utmParamsReducer.content,
		term: state.utmParamsReducer.term
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setUtmParams: utmParams => dispatch(setUtmParams(utmParams))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);