import React from 'react';

import { Widget } from '@typeform/embed-react'
import FormLayout from '../../components/Layouts/FormLayout/FormLayout';

const InvestorFeedback = props => {

	return (
		<FormLayout>
			<Widget 
				id='cEGVj0Op' 
				style={{ width:'100%', height:'600px' }} 
				inlineOnMobile={true}
			/>
		</FormLayout>
	)
}

export default InvestorFeedback;

